import { useTranslation } from 'react-i18next';
import StandardModal from '../../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../../shared/wizard/WizardStepsIndicator';
import ManageAccess from '../ManageAccess';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import { useCallback, useState } from 'react';
import useInviteUser from '../../../hooks/useInviteUser';
import { ClientFormUserRole } from '../../../models/ClientFormUserRoles';

const CreateAccessStep = () => {
  const { nextStep, prevStep, stepNames, defaults, setDefaults, clientUsers, onUserInvited, newTemplate } = useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'common']);
  const { inviteUserMode, setInviteUserMode, inviteUser, setInvitedUser, invitedUser, setInviteUserDisable, inviteUserDisable } = useInviteUser();
  const [isSaving, setIsSaving] = useState(false);
  const internalOnClose = useCallback(() => {
    setInviteUserMode(false);
  }, [setInviteUserMode]);

  const onSaveUserInvite = useCallback(() => {
    setIsSaving(true);
    inviteUser()?.then((res) => {
      setDefaults &&
        setDefaults({
          ...defaults,
          users: { ...defaults.users, [res.data.userId]: invitedUser?.defaultClientFormRole || ClientFormUserRole.Viewer },
        });

      onUserInvited();
      setInviteUserMode(false);
      setIsSaving(false);
    });
  }, [defaults, inviteUser, invitedUser?.defaultClientFormRole, onUserInvited, setDefaults, setInviteUserMode]);

  return (
    <StandardModal
      title={t('manage-wizard.create-class.title')}
      cancelButtonTitle={t('manage-wizard.buttons.back')}
      onCancelClick={!inviteUserMode ? prevStep : internalOnClose}
      confirmButtonTitle={!inviteUserMode ? t('manage-wizard.buttons.next') : t('common:permissions-modal.manage-add')}
      onConfirmClick={() => (!inviteUserMode ? nextStep && nextStep('createPeriodicReview') : onSaveUserInvite())}
      tertiaryButtonTitle={t('common:add-or-invite-modal.switch.invite')}
      onTertiaryButtonClick={!inviteUserMode ? () => setInviteUserMode(true) : undefined}
      confirmDisabled={!inviteUserMode ? false : inviteUserDisable}
      confirmLoading={isSaving}
    >
      {stepNames && nextStep && (
        <WizardStepsIndicator
          activeStepIndex={2}
          stepNames={stepNames.filter(
            (x) =>
              x === 'createClass' ||
              x === 'createClassConfig' ||
              x === 'createAccess' ||
              x === 'createPeriodicReview' ||
              x === 'createDownloadPdfPreferences',
          )}
          onStepChange={nextStep}
        />
      )}
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        <div className="text-dpm-16 font-medium">{t('manage-wizard.access')}</div>
        <ManageAccess
          defaults={defaults}
          clientUsers={clientUsers}
          onDefaultsChange={(defaults) => setDefaults && setDefaults(defaults)}
          inviteUserMode={inviteUserMode}
          onUserInviteValid={(value) => setInviteUserDisable(!value)}
          onUserInviteChange={setInvitedUser}
          requireApprover={newTemplate.requiresApproval}
          requireValidator={newTemplate.requiresValidation}
        />
      </div>
    </StandardModal>
  );
};

export default CreateAccessStep;
