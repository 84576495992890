import { FC, useCallback, useEffect } from 'react';
import { ModuleTemplate } from '../../models/Module';
import Checkbox from '../shared/form-control/Checkbox';
import { useTranslation } from 'react-i18next';

type CreateClassProps = {
  template: ModuleTemplate;
  onTemplateChange: (template: ModuleTemplate) => void;
};

const ManageClassConfig: FC<CreateClassProps> = (props) => {
  const { template, onTemplateChange } = props;
  const { t } = useTranslation(['documents', 'organisation']);

  const addModuleValue = useCallback(
    <TKey extends keyof ModuleTemplate>(key: TKey, value: ModuleTemplate[TKey]) => {
      onTemplateChange({ ...template, [key]: value });
    },
    [template, onTemplateChange],
  );

  useEffect(() => {
    if (!template?.requiresValidation && template?.requiresValidationSignature) {
      addModuleValue('requiresValidationSignature', false);
    }
  }, [addModuleValue, template?.requiresValidation, template?.requiresValidationSignature]);

  useEffect(() => {
    if (!template?.requiresApproval && template?.requiresApprovalSignature) {
      addModuleValue('requiresApprovalSignature', false);
    }
  }, [addModuleValue, template?.requiresApproval, template?.requiresApprovalSignature]);

  useEffect(() => {
    if (!template?.requiresApproval && !template.requiresValidation && template?.requiresOwnerAcknowledgement) {
      addModuleValue('requiresOwnerAcknowledgement', false);
    }
  }, [
    addModuleValue,
    template?.requiresApproval,
    template.requiresApprovalSignature,
    template?.requiresOwnerAcknowledgement,
    template.requiresValidation,
  ]);

  return (
    <>
      <div className="my-4">
        <div className="text-dpm-16 font-medium">{t('manage-wizard.review-process')}</div>
        <div className="ml-4">
          <Checkbox
            label={t('organisation:modules.modals.create-class.requires-validation')}
            description={t('organisation:modules.modals.create-class.requires-validation-description')}
            value={template?.requiresValidation}
            onChange={(selectedValue) => addModuleValue('requiresValidation', selectedValue)}
          />

          <Checkbox
            className="my-2"
            disabled={!template?.requiresValidation}
            value={template?.requiresValidationSignature}
            onChange={(requiresValidationSignature) => addModuleValue('requiresValidationSignature', requiresValidationSignature)}
            label={t('organisation:modules.modals.create-class.requires-validation-signature')}
            description={t('organisation:modules.modals.create-class.requires-validation-signature-description')}
          />
        </div>
        <div className="text-dpm-16 mt-4 font-medium">{t('manage-wizard.approval-process')}</div>
        <div className="ml-4">
          <Checkbox
            label={t('organisation:modules.modals.create-class.requires-approval')}
            description={t('organisation:modules.modals.create-class.requires-approval-description')}
            value={template?.requiresApproval}
            onChange={(selectedValue) => addModuleValue('requiresApproval', selectedValue)}
          />

          <Checkbox
            disabled={!template?.requiresApproval}
            className="my-2"
            value={template?.requiresApprovalSignature}
            onChange={(requiresApprovalSignature) => addModuleValue('requiresApprovalSignature', requiresApprovalSignature)}
            label={t('organisation:modules.modals.create-class.requires-approval-signature')}
            description={t('organisation:modules.modals.create-class.requires-approval-signature-description')}
          />
        </div>
        <div className="text-dpm-16 mt-4 font-medium">{t('manage-wizard.requires-owner-acknowledgement-process')}</div>
        <div className="ml-4">
          <Checkbox
            label={t('organisation:modules.modals.create-class.requires-owner-acknowledgement')}
            description={t('organisation:modules.modals.create-class.requires-owner-acknowledgement-description')}
            value={template?.requiresOwnerAcknowledgement}
            onChange={(selectedValue) => addModuleValue('requiresOwnerAcknowledgement', selectedValue)}
            disabled={!template?.requiresApproval && !template?.requiresValidation}
          />
        </div>
      </div>
    </>
  );
};

export default ManageClassConfig;
